<div class="pdf__botones" *ngIf="esVisibleHerramientas">
    <div class="center-item">
      <div class="btn-accion" (click)="imprimirPdf()">
        <img src="../../../../assets/imagenes/icons/printing.png">
      </div>
    </div>
    <div class="center-item">
      <div class="btn-accion" (click)="descargarPdf()">
        <img src="../../../../assets/imagenes/icons/download.png">
      </div>
    </div>
    <div class="btn_zoom">
      <button type="button" class="btn_zoomPdf"  (click)="zoomOut()"><span
          class="material-symbols-outlined">zoom_out</span></button>
    </div>
    <div class="btn_zoom">
      <button type="button" class="btn_zoomPdf"  (click)="zoomIn()"><span
          class="material-symbols-outlined">zoom_in</span></button>
    </div>
  </div>
  <div id="print" class="pdf-container" *ngIf="visualizarPdf">
    <!-- https://www.npmjs.com/package/ng2-pdf-viewer -->
    <pdf-viewer [src]="pdfBlob" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="zoom" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true" [render-text-mode]="2" [external-link-target]="'blank'" [autoresize]="true"
    [show-borders]="false" class="viewer"></pdf-viewer>
  </div>