<button
  [class]="buttonClass"
  class="btn generic-btn"
  [disabled]="isDisabled"
  [attr.type]="buttonType"
  matTooltip="{{ tooltipText }}"
  matTooltipPosition="above"
  [attr.aria-label]="ariaLabel">
  {{ buttonName }}
</button>
