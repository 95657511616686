import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AutenticacionStoreService } from 'src/app/feature/autenticacion/store/autenticacion-store.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SweetAlertService } from '../services/sweet-alert.service';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { constantes } from '../data/Constantes';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorsService implements HttpInterceptor {
  public usuarioToken$: Observable<any>;
  public userSession$: Observable<any>;
  public token?: string = '';
  public session?: any;
  @BlockUI() blockUI: NgBlockUI;

  private jwtHelper: JwtHelperService;

  constructor(
    private autenticacionStoreService: AutenticacionStoreService,
    private sweetAlertSerice: SweetAlertService,
    private router: Router
  ) {
    this.jwtHelper = new JwtHelperService();
    this.userSession$ = this.autenticacionStoreService.obtenerSession$();
    this.usuarioToken$ = this.autenticacionStoreService.obtenerSession$();
  }

  intercept(
    request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      this.usuarioToken$.pipe().subscribe((usuario: any) => this.token = usuario.token);
      this.userSession$.pipe().subscribe(session => this.session = session);

      if(this.token && this.jwtHelper.isTokenExpired(this.token) || !this.session) {
        this.blockUI.stop();
        this.sweetAlertSerice.sweetAlertAccionAceptar(constantes.ERROR_SESION_EXPIRADA).then(resp => {
          this.autenticacionStoreService.removeSession();
          this.router.navigate(['/autenticacion']);
        });
        return;
      };

      if (this.excluirRutas(request.url)) return next.handle(request);
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.token,
        },
      });

      return next.handle(request);
  }

  private excluirRutas(url: string): boolean {
    let result = false;
    environment.blacklistedRoutes.forEach((item) => {
      if (url.includes(item)) {
        result = true;
        return result;
      }
    });
    return result;
  }
}
