import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-clear-form-button',
  templateUrl: './clear-form-button.component.html',
  styleUrls: ['./clear-form-button.component.css']
})
export class ClearFormButtonComponent {
  @Input() buttonName = 'Enviar'; // Texto del botón.
  @Input() ariaLabel = 'Botón de limpiar'; // Texto accesible para lectores de pantalla.
}
