<div class="informativo">
    <div class="tituloOpcion">

        <div class="opcion" routerLink="/empresa/tarifa-servicio" *ngIf="tarifaServicio">
            <div class="opcion_titulo" routerLinkActive="selectTitle" *ngIf="validarOpcion(constantesOpciones.TARIFA_SERVICIO)"><p>Tarifa servicio</p></div>
        </div>
        <div class="opcion" routerLink="/empresa/administrar-empresas" *ngIf="crearEmpresa">
            <div class="opcion_titulo" routerLinkActive="selectTitle"><p>Administrar empresas</p></div>
        </div>
        <div class="opcion" *ngIf="opcionesReportes && ( validarOpcion(constantesOpciones.COMISIONES_POR_REMESAS) || validarOpcion(constantesOpciones.BALANCE_GENERAL_EMPRESAS))">
            <div class="opcion_titulo" 
                *ngIf="validarOpcion(constantesOpciones.COMISIONES_POR_REMESAS)"
                routerLink="/reportes/comision-por-remesas"
                routerLinkActive="selectTitle"
                >
                <p>Comisiones por remesas</p>
            </div>

            <div class="opcion_titulo"
                *ngIf="validarOpcion(constantesOpciones.BALANCE_GENERAL_EMPRESAS)"
                routerLink="/reportes/balance-general-empresa"
                routerLinkActive="selectTitle"
                >
            <p>Balance productos por empresa</p></div>
        </div>

    </div>
</div>