<mat-form-field
    class="filtro__formCalendar" 
    appearance="none" 
    [showButtonBar]="true" 
    [readonlyInput]="true">
    <input matInput
        [value]="utilService.formatDateParamPipe(fechaMax)"
        [max]="fechaMax"
        [matDatepicker]="picker"
        id="fecha" placeholder=" "
        (dateChange)="filtrarFecha($event)"
        readonly="true">
    <mat-datepicker-toggle 
        matSuffix [for]="picker"
        matTooltipPosition="above">
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>