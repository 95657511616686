<label class="label_input">{{nameSelect}}</label>
<mat-select
  class="filtro_select"
  placeholder="Todos"
  [formControl]="formGroup.get(controlName)"
  #select>
  
  <!-- Filtro de selección -->
  <mat-select-filter 
    *ngIf="select.focused && filter"
    displayMember="nombre"
    [array]="filterLista"
    (filteredReturn)="lista = $event">
  </mat-select-filter>

  <!-- Opciones filtradas -->
  <mat-option
    *ngFor="let i of lista; trackBy: trackByLista"
    [value]="i?.id">
    {{ i?.nombre | titlecase }}
  </mat-option>
</mat-select>