import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { OpcionesPerfilRolDto } from 'src/app/core/data/OpcionesPerfilRolDto';
import { opciones } from 'src/app/core/data/rolOpciones';
import { SubjectsService } from 'src/app/core/services/subjects.service';
import { AutenticacionStoreService } from 'src/app/feature/autenticacion/store/autenticacion-store.service';

@Component({
  selector: 'app-titulo-informativo-opciones',
  templateUrl: './titulo-informativo-opciones.component.html',
  styleUrls: ['./titulo-informativo-opciones.component.css']
})
export class TituloInformativoOpcionesComponent implements OnInit {


  // COM Roles perfil
  public opcionesUsuario$: Observable<any>;
  public opcionesUsuario: any;
  public constantesOpciones = opciones;

  // COM Estado de opciones
  public opcionesEmpresa = false;
  public tarifaServicio = false;
  public crearEmpresa = false;

  public opcionesReportes = false;
  public comisionRemesas = false;
  public balanceGeneral = false;

  public pathRedireccion: string;

  constructor(
    private router: Router,
    private subjectService: SubjectsService,
    private autenticacionStoreService: AutenticacionStoreService,
    private readonly cdr: ChangeDetectorRef,
    ) {
      this.opcionesUsuario$ = this.autenticacionStoreService.obtenerOpcionesUsuario$();
      this.opcionesUsuario = new OpcionesPerfilRolDto();
    }

  ngOnInit(): void {
    this.validarOpcionesNavegacion();
    this.cdr.detectChanges();
  }

  validarOpcionesNavegacion() {
    this.subjectService.pathVistaToSideBarGet.subscribe({
      next: (path: string) => {
        this.pathRedireccion = path;
        this.validarModulos();
        this.opcionSeleccionada(path);
      }
    });
  }

  validarModulos() {
    this.cancelarModulos();
    this.cancelarVentanas();

    switch (this.pathRedireccion) {
      case 'tarifaServicio':
        this.opcionesEmpresa = true;
        this.tarifaServicio = true;
        break;
      case 'crearEmpresa':
        this.opcionesEmpresa = true;
        this.crearEmpresa = true;
        break;
      case 'comisionRemesas':
        this.opcionesReportes = true;
        this.comisionRemesas = true;
        break;
      case 'balanceGeneral':
        this.opcionesReportes = true;
        this.balanceGeneral = true;
        break;
    }
  }

  cancelarModulos(): void {
    this.opcionesEmpresa = false;
    this.opcionesReportes = false;
  }

  cancelarVentanas() {
    this.tarifaServicio = false;
    this.crearEmpresa = false;
    this.comisionRemesas = false;
    this.balanceGeneral = false;
  }

  public validarOpcion(valor: number) {
    this.opcionesUsuario$.subscribe((data: any) => {
      this.opcionesUsuario = data;
    });
    return Object.values(this.opcionesUsuario).includes(valor);
  }

  opcionSeleccionada(opcion: string) {
    if(this.opcionesReportes) {
      switch (opcion) {
        case 'comisionRemesas':
          this.router.navigate(['reportes/comision-por-remesas']);
          break;
        case 'balanceGeneral':
          this.router.navigate(['reportes/balance-general-empresa']);
          break;
      }
    }
  }

}
