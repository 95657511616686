import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

import { AutenticacionStoreService } from '../../feature/autenticacion/store/autenticacion-store.service';
import { constantes } from '../data/Constantes';
import { environment } from '../../../environments/environment';
import { LoginRequestService } from './login-request.service';


@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  constructor(
    private readonly autenticacionStoreService: AutenticacionStoreService,
    private loginRequestService: LoginRequestService
  ) { }

  public sweetAlertError500(mensajeError: any): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `${mensajeError}`,
      confirmButtonColor: ' #242E7C',
      confirmButtonText: `Aceptar`,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  }

  public sweetAlertError503(): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `${constantes.ERROR_DE_SERVIDOR_503}`,
      confirmButtonColor: ' #242E7C',
      confirmButtonText: `Aceptar`,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    });
  }

  public sweetAlertCamposVacios(text: string): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `${text}`,
      confirmButtonColor: ' #242E7C',
      confirmButtonText: `Aceptar`,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    });
  }

  public sweetAlertError200(): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `${constantes.MENSAJE_NOTIFICACION_200}`,
      confirmButtonColor: ' #242E7C',
      confirmButtonText: `Aceptar`,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    });
  }

  public sweetAlertErrorRespuestaExitosa(message: string): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `${message}`,
      confirmButtonColor: ' #242E7C',
      confirmButtonText: `Aceptar`,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    });
  }

  public sweetAlertCierreSesion(email: string): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `${constantes.MENSAJE_SOLICITUD_CIERRE_SESSION}`,
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: ' #242E7C',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        this.autenticacionStoreService.removeSession();
        this.autenticacionStoreService.removePerfil();
        this.autenticacionStoreService.removerOpcionesUsuario();
        this.loginRequestService.cerrarSesion(email);
        location.replace(constantes.LOGIN);
      }
    });
  }

  public sweetAlertCierreSesionGuardian(): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `${constantes.MENSAJE_SOLICITUD_CIERRE_SESSION_GUARDIAN}`,
      showCancelButton: false,
      confirmButtonText: `Aceptar`,
      confirmButtonColor: ' #242E7C',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then(result => {
      if (result.isConfirmed) {
        this.autenticacionStoreService.removeSession();
      }
    });
  }

  public sweetAlertAccionBoton(message: string): Promise<any> {
    return new Promise(resolve => {
      Swal.fire({
        title: `<p class="label_alert">Administrativo</p>`,
        html:
          '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
          '<br>' +
          `${message}`,
        showDenyButton: true,
        confirmButtonText: `Aceptar`,
        denyButtonText: 'Cancelar',
        confirmButtonColor: ' #242E7C',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          resolve(true);
        }
      });
    });
  }
  public sweetAlertAccionBotonDosAcciones(message: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: `<p class="label_alert">Administrativo</p>`,
        html:
          '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
          '<br>' +
          `${message}`,
        showCancelButton: true,
        confirmButtonText: `Aceptar`,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: ' #242E7C',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          resolve(true);
        }else{
          reject(false);
        }
      });
    });
  }

  public sweetAlertAccionBotonDosMensajes(
    message: string,
    messageTwo: string
  ): Promise<any> {
    return new Promise(resolve => {
      Swal.fire({
        title: `<p class="label_alert">Administrativo</p>`,
        html:
          '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
          '<br>' +
          `<p style="padding:0 1rem; text-align : justify;">${message}</p>` +
          `<p style="padding:0 1rem; text-align : justify;">${messageTwo}</p>`,
        confirmButtonText: `Aceptar`,
        confirmButtonColor: ' #242E7C',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          resolve(true);
        }
      });
    });
  }

  public sweetAlertInformativo(message: string): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `${message}`,
      confirmButtonColor: ' #242E7C',
      confirmButtonText: `Aceptar`,
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    });
  }

  public sweetAlertErrorNoControlado(mensaje: string): void {
    Swal.fire({
      title: `<p class="label_alert">Administrativo</p>`,
      html:
        '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
        '<br>' +
        `<p style="padding:0 1rem; text-align : justify;">${mensaje}</p>`,
      confirmButtonText: `Aceptar`,
      confirmButtonColor: ' #242E7C',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then(result => {
      if (result.isConfirmed) {
        this.autenticacionStoreService.removeSession();
      }
    });
  }

  public sweetAlertAccionAceptar(
    message: string,
  ): Promise<any> {
    return new Promise(resolve => {
      Swal.fire({
        title: `<p class="label_alert">Administrativo</p>`,
        html:
          '<img src="../../../assets/imagenes/logo/Logo nuevo color Todo+.png" class="img-responsive imagen_alert"></img> ' +
          '<br>' +
          `<p style="padding:0 1rem; text-align : center;">${message}</p>`,
        confirmButtonText: `Aceptar`,
        confirmButtonColor: ' #242E7C',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          resolve(true);
        }
      });
    });

  }

  public sweetAlertHTML(title: string, dataHtml: any): void {
    Swal.fire({
      title: `<p class="label_alert">${title}</p>`,
      html: dataHtml,
      width: 'auto',
      confirmButtonText: `Aceptar`,
      confirmButtonColor: ' #242E7C',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      reverseButtons: true
    });

  }

  public AccionAceptarYHtml(dataHtml: string, titulo: string): Promise<any> {
    return new Promise(resolve => {
      Swal.fire({
        title: `<p class="label_alert">${titulo}</p>`,
        html:dataHtml,
        confirmButtonText: `Aceptar`,
        confirmButtonColor: ' #242E7C',
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: true,
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          resolve(true);
        }
      });
    });
  }

   public alertAccionAceptarTextarea(data: any): Promise<any> {
    return new Promise(resolve => {
      Swal.fire({
        title: '<h1 class="label_alert">Confirmar anulación<h1>',
        html: `<p text-alert>¿Está seguro que desea anular el depósito por valor de ${data}?</p>
              <textarea id="textarea-input" maxlength="50" placeholder="Ingrese máximo 50 caracteres."></textarea>`,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: ' #242E7C',
        showDenyButton: true,
        denyButtonText: 'Cancelar',
        reverseButtons: true,
        preConfirm: () => {
          const textareaValue = (document.getElementById('textarea-input') as HTMLTextAreaElement).value;
          if (!textareaValue) {
            Swal.showValidationMessage(constantes.ERROR_CAMPO_NULL);
          }
          return textareaValue;
        },
      }).then((result) => {
        if (result.isConfirmed && result.value) {
          resolve(result.value);
        }
      });
    });
  }
}
