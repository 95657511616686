import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { MaterialModule } from './libreriasUI/material/material.module';
const lang = 'en-US';

// COM Componentes ****************
import { NavBarComponent } from './layoutComponents/nav-bar/nav-bar.component';
import { SideBarComponent } from './layoutComponents/side-bar/side-bar.component';
import { NotFoundComponent } from './layoutComponents/not-found/not-found.component';
import { SafeUrlPipe } from '../core/pipes/safe-url.pipe';
import { FooterComponent } from './layoutComponents/footer/footer.component';

import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatMenuModule} from '@angular/material/menu';

import { CampoFechaComponent } from './custom-components/campo-fecha/campo-fecha.component';
import { FiltroBuscarComponent } from './custom-components/filtro-buscar/filtro-buscar.component';
import { CarouselComponent } from './layoutComponents/carousel/carousel.component';
import { NavBarLandingComponent } from './layoutComponents/nav-bar-landing/nav-bar-landing.component';
import { TituloInformativoOpcionesComponent } from './layoutComponents/titulo-informativo-opciones/titulo-informativo-opciones.component';
import { VisualizadorPdfComponent } from './layoutComponents/visualizador-pdf/visualizador-pdf.component';
import { SelectComponent } from './custom-components/select/select.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { TituloInformativoComponent } from './layoutComponents/titulo-informativo/titulo-informativo.component';
import { ActionButtonComponent } from './custom-components/action-button/action-button.component';
import { ClearFormButtonComponent } from './custom-components/clear-form-button/clear-form-button.component';
import { FiltroFechaComponent } from './custom-components/filtro-fecha/filtro-fecha.component';
import { InputBusquedaComponent } from './custom-components/input-busqueda/input-busqueda.component';

@NgModule({
  declarations: [
    NavBarComponent,
    SideBarComponent,
    NotFoundComponent,
    SafeUrlPipe,
    FooterComponent,
    CampoFechaComponent,
    FiltroBuscarComponent,
    NavBarLandingComponent,
    CarouselComponent,
    TituloInformativoOpcionesComponent,
    TituloInformativoComponent,
    VisualizadorPdfComponent,
    SelectComponent,
    ActionButtonComponent,
    ClearFormButtonComponent,
    FiltroFechaComponent,
    InputBusquedaComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    SwiperModule,
    FormsModule,
    MatMenuModule,
    PdfViewerModule,
    ReactiveFormsModule,
    NgxMatTimepickerModule.setLocale(lang),
  ],
  exports: [
    NavBarComponent,
    NavBarLandingComponent,
    SideBarComponent,
    NotFoundComponent,
    SafeUrlPipe,
    FooterComponent,
    CampoFechaComponent,
    FiltroBuscarComponent,
    CarouselComponent,
    TituloInformativoOpcionesComponent,
    TituloInformativoComponent,
    VisualizadorPdfComponent,
    SelectComponent,
    ActionButtonComponent,
    ClearFormButtonComponent,
    FiltroFechaComponent,
    InputBusquedaComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [{ provide: LOCALE_ID, useValue: lang }]
})
export class SharedModule { }
