<div class="search-container">
  <em class="fa fa-search iconPlaceholder"></em>
  <input
    type="text"
    id="buscar"
    class="input-buscar"
    id="input-buscar"
    (input)="buscarInformacion($event.target.value)"
    placeholder="Buscar..."
    #input />
  <mat-spinner *ngIf="loading" diameter="25" class="ms-2"></mat-spinner>
</div>
