import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css']
})
export class ActionButtonComponent {

  @Input() buttonClass = '';
  @Input() buttonName = 'Enviar'; // Texto del botón.
  @Input() isDisabled = false; // Deshabilitado o no.
  @Input() tooltipText = ''; // Texto para el tooltip.
  @Input() buttonType = 'button'; // Tipo del botón (button, submit, etc.).
  @Input() ariaLabel = 'Botón genérico'; // Texto accesible para lectores de pantalla.
}
