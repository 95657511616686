
// COM Rutas API REST *****************************
const CONSULTAR_INFORMACION_USUARIO = '/autenticacion/consultarInformacionUsuario';
const VALIDAR_SESSION_ACTIVA = '/autenticacion/validarSessionGuardianComponentes';
const LOGIN_ADMINISTRATIVO = '/adm/login/ventas/auth/ventas';
const LOGIN_DATOS_USUARIO = '/adm/usuarios/obtenerDatosUsuario';
const LOGIN = '/autenticacion/login';
const LOGOUT = '/adm/login/logout';
const OPCIONESUSUARIO = '/adm/usuarios/obtenerOpcionesPorUsuario';

// COM Empresas
const CONSULTAR_EMPRESA_POR_ID = '/adm/empresa/consultarinformacionempresa/';
const CONSULTAR_EMPRESAS = '/adm/empresa/consultarempresas';
const CREAR_O_EDITAR_EMPRESA = '/adm/empresa/almacenarempresa';
const ID_HOMOLOGACION_INVALIDO = 'Id inválido';

// Reportes
const REPORTE_COMISION_REMESAS = '/adm/empresa/reportecomisionesremesas';
const REPORTE_BALANCE_GENERAL = '/adm/empresa/balancegeneralproductosporempresa';
const REPORTE_DEPOSITOS_BANCARIOS = '/adm/reporte/depositos-bancarios/activos';
const REPORTE_DEPOSITOS_BANCARIOS_ANULADOS = '/adm/reporte/depositos-bancarios/inactivos';


// COM Mensajes SWEET ALERT *****************************
const URL_LOGO_SWEET_ALERT = 'Administrativo Todo+';
const MENSAJE_SOLICITUD_CIERRE_SESSION = '¿Está seguro de cerrar sesión?';
const MENSAJE_NOTIFICACION_200 = 'Respuesta de acción exitosa.';
const MENSAJE_EDITARNOT = 'Lo sentimos esta función aún no está disponible.';
const MENSAJE_SOLICITUD_CIERRE_SESSION_GUARDIAN = 'Se detectó que tiene una sesión diferente a la actual o está intentando acceder sin estar autenticado.';
const MENSAJE_SOLICITUD_CIERRE_SESION_POR_SEGURIDAD_NOT_FOUND_404 = 'Se detecto que intentas acceder a una URL no valida.';
const VALIDAR_ACCION_LIMPIAR_FORMULARIO = '¿Está seguro de limpiar los campos del formulario?';
const DESCARGA_EXITOSA_BASE64_EXCEL = 'Se ha descargado exitosamente el reporte Excel.';
const DESCARGA_EXITOSA_BASE64_PDF = 'Se ha descargado exitosamente el reporte Pdf.';
const MENSAJE_USUARIO_VERIFICADO_CON_EXITO = 'Usuario verificado con éxito, se le enviará un mensaje de texto con un código';
const CODIGO_VERIFICACION_EXITOSO = 'Se ha verificado exitosamente el código ingresado.';


// COM  Mensajes Error SWEET ALERT **************************
const ERROR_DE_SERVIDOR_503 = 'No se puede comunicar con el servidor. Verifique su conexión a Internet y vuelve a intentarlo.';
const ERROR_CAMPO_NULL = 'Campo obligatorio.';
const ERROR_RESPUESTA_VACIA_BASE64_EXCEL = 'Ha ocurrido un error en la descarga del reporte Excel.';
const ERROR_RESPUESTA_VACIA_BASE64_PDF = 'Ha ocurrido un error en la descarga del reporte Pdf.';
const FORMULARIO_NO_VALIDO = 'Verifique que los datos seleccionados en el formulario estén completos.';
const EXCEDIO_CANTIDAD_INTENTOS_FALLIDOS_UNO = 'Se ha bloqueado temporalmente el ingreso por intentos fallidos. ';
const EXCEDIO_CANTIDAD_INTENTOS_FALLIDOS_DOS = 'Se enviará un código por mensaje de texto para autenticarse en la app.';
const ERROR_GENERICO = 'Ha ocurrido un error inesperado, por favor intente nuevamente.';
const ERROR_CODE_ID_HOMOLOGACION = 'A05';
const ERROR_SESION_EXPIRADA = 'La sesión ha expirado, por favor ingrese nuevamente.';

const CONTREASENA_NO_COINCIDEN = 'Contraseñas no coinciden';
// eslint-disable-next-line max-len
const ERROR_NO_CONTROLADO = 'Ha ocurrido un error inesperado, se redireccionará nuevamente a la app inicial para validar autenticación.';
const MENSAJE_USUARIO_NO_VALIDO = 'Usuario no válido, por favor verifique.';
const MENSAJE_EXCEDIO_CANTIDAD_PROGRAMACION_SORTEO = 'Has programado el máximo de ';
const MENSAJE_EXCEDIO_CANTIDAD_PROGRAMACION_SORTEO_DOS = 'sorteos, para la rifa ';
const MONTOMAX_MENOR_MONTOMIN = 'Monto inferior al anterior.';
const MONTO_INVALIDO = 'Monto invalido.';
const VALOR_INVALIDO = 'Valor invalido.';
const EXCEDIO_CANT_MONTO = 'Monto maximo 999,999';
const EXCEDIO_VALOR_MAXIMO = 'Monto maximo 999,999.99';
const EXCEDIO_PORCENTAJE = 'Porcentaje maximo 100.';
const INGRESA_UN_PORCENTAJE = 'Ingresa un porcentaje.';
const VALOR_SERVICIO_EXCEDIDO = 'El valor excede el monto máximo.';
const ERROR_DIMENSIONES = 'Las dimensiones de la imagen no son válidas.';
const ERROR_IMAGEN = 'Error al procesar la imagen.';
const ERROR_IMAGEN_NO_VALIDA = 'El archivo seleccionado no es una imagen válida.';
const ERROR_ARCHIVO = 'No se seleccionó ningún archivo.';
const MONTO_MAXIMO = 999999;
const PORCENTAJE_MAXIMO = 100;


// COM Toast ************************************************
const TOAST_NUEVO_MENSAJE = 'Hemos detectado que tienes ';
const TOAST_CONTRASENA_REQUERIDA = 'Contraseña requerida';
const TOAST_USUARIO_REQUERIDA = 'Usuario requerido';

// COM Redireccion de componentes **************************
const REDIRECCIONARHOME = '/home';

// COM Expresiones Regulares **************************
// eslint-disable-next-line max-len
const REGEX_VALIDAR_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGEX_VALIDAR_CADENA_BASE64 = /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i;


// COM Estados
const ESTADO_ACTIVO = 'A';
const ESTADO_INACTIVO = 'I';
const MENOS_UNO = -1;
const UNO = 1;

// COM Tipo reporte
const TIPO_REPORTE_PDF = 0;
const TIPO_REPORTE_EXCEL = 1;
const TIPO_REPORTE_RESUMIDO = 1;
const TIPO_REPORTE_DETALLADO = 0;

// COM Codigos error
const CODIGO_USUARIO_SESSION_INACTIVA = 'ADM27';

// COM MIME ---> Multipurpose Internet Mail Extensions
const MIME_PDF_1 = 'application/pdf';
const MIME_PDF_2 = 'data:application/pdf;base64';
const MIME_PDF_3 = 'application/pdf;base64';
const MIME_EXCEL = 'data:application/vnd.ms-excel;base64';
const MIME_EXCEL_2 = 'data:application/octet-stream;base64';

// COM Util focus
const CAMPO_CONTRASENA = 'contrasena';
const CAMPO_USUARIO = 'usuario';


// COM Roles
const ROL_RECAUDADOR = 3;

// COM Scroll visor id
const SCROLL_TO_PDF_VISOR = '#visorPdf';

// COM Agrupador
const AGRUPADOR_AGENCIA = 3;
const AGRUPADOR_EMPRESA = 1;

// COM Tipo de sorteo
const PRINCIPAL = 1;
const SECUNDARIO = 2;

// COM Tipo de reporte
const TIPOREPORTE_DEFECTO = 1;

// COM Formatos fechas
const FORMATO_FECHAS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

// COM Sidebar
const MODULO_INICIO = 'inicio';
const MODULO_EMPRESA = 'empresa';
const MODULO_REPORTES = 'reportes';
const ITEM_TARIFASERVICIO = 'tarifaServicio';
const ITEM_CREAREMPRESA = 'crearEmpresa';
const ITEM_DEPOSITOS_BANCARIOS = 'depositosBancarios';
const ITEM_COMISIONREMESAS = 'comisionRemesas';
const ITEM_BALANCE_GENERAL = 'balanceGeneral';
const ITEM_REPORTE_DEPOSITOS_BANCARIOS = 'reporteDepositosBancarios';
const ITEM_REPORTE_DEPOSITOS_BANCARIOS_ANULADOS = 'reporteDepositosBancariosAnulados';

const RUTA_TARIFASERVICIO = 'empresa/tarifa-servicio';
const RUTA_CREAREMPRESA = 'empresa/administrar-empresas';
const RUTA_DEPOSITOS_BANCARIOS = 'empresa/depositos-bancarios';
const RUTA_COMISIONREMESAS = 'reportes/comision-por-remesas';
const RUTA_REPORTE_DEPOSITOS_BANCARIOS = 'reportes/depositos-bancarios';
const RUTA_REPORTE_DEPOSITOS_BANCARIOS_ANULADOS = 'reportes/depositos-bancarios-anulados';

export const constantes = {
  CONSULTAR_INFORMACION_USUARIO,
  URL_LOGO_SWEET_ALERT,
  ERROR_DE_SERVIDOR_503,
  ERROR_CAMPO_NULL,
  REDIRECCIONARHOME,
  REGEX_VALIDAR_EMAIL,
  MENSAJE_SOLICITUD_CIERRE_SESSION,
  MENSAJE_NOTIFICACION_200,
  MENSAJE_EDITARNOT,
  ESTADO_ACTIVO,
  ESTADO_INACTIVO,
  MENOS_UNO,
  TOAST_NUEVO_MENSAJE,
  VALIDAR_SESSION_ACTIVA,
  MENSAJE_SOLICITUD_CIERRE_SESSION_GUARDIAN,
  CODIGO_USUARIO_SESSION_INACTIVA,
  VALIDAR_ACCION_LIMPIAR_FORMULARIO,
  UNO,
  ERROR_RESPUESTA_VACIA_BASE64_EXCEL,
  DESCARGA_EXITOSA_BASE64_EXCEL,
  TIPO_REPORTE_EXCEL,
  TIPO_REPORTE_PDF,
  TIPO_REPORTE_RESUMIDO,
  TIPO_REPORTE_DETALLADO,
  FORMULARIO_NO_VALIDO,
  ERROR_RESPUESTA_VACIA_BASE64_PDF,
  DESCARGA_EXITOSA_BASE64_PDF,
  MIME_PDF_1,
  MIME_PDF_2,
  MIME_PDF_3,
  MIME_EXCEL,
  MIME_EXCEL_2,
  ROL_RECAUDADOR,
  REGEX_VALIDAR_CADENA_BASE64,
  SCROLL_TO_PDF_VISOR,
  LOGIN_ADMINISTRATIVO,
  LOGIN_DATOS_USUARIO,
  LOGIN,
  LOGOUT,
  OPCIONESUSUARIO,
  AGRUPADOR_AGENCIA,
  AGRUPADOR_EMPRESA,
  EXCEDIO_CANTIDAD_INTENTOS_FALLIDOS_UNO,
  EXCEDIO_CANTIDAD_INTENTOS_FALLIDOS_DOS,
  TOAST_CONTRASENA_REQUERIDA,
  TOAST_USUARIO_REQUERIDA,
  CAMPO_CONTRASENA,
  CAMPO_USUARIO,
  MENSAJE_USUARIO_VERIFICADO_CON_EXITO,
  MENSAJE_SOLICITUD_CIERRE_SESION_POR_SEGURIDAD_NOT_FOUND_404,
  CODIGO_VERIFICACION_EXITOSO,
  CONTREASENA_NO_COINCIDEN,
  MENSAJE_USUARIO_NO_VALIDO,
  ERROR_NO_CONTROLADO,
  PRINCIPAL,
  SECUNDARIO,
  MENSAJE_EXCEDIO_CANTIDAD_PROGRAMACION_SORTEO,
  MENSAJE_EXCEDIO_CANTIDAD_PROGRAMACION_SORTEO_DOS,
  TIPOREPORTE_DEFECTO,
  ERROR_GENERICO,
  ERROR_CODE_ID_HOMOLOGACION,
  ERROR_DIMENSIONES,
  ERROR_IMAGEN,
  ERROR_IMAGEN_NO_VALIDA,
  ERROR_ARCHIVO,
  ERROR_SESION_EXPIRADA,

  // COM Formatos
  FORMATO_FECHAS,

  // COM Plan tarifa servico
  MONTOMAX_MENOR_MONTOMIN,
  MONTO_INVALIDO,
  VALOR_INVALIDO,
  EXCEDIO_CANT_MONTO,
  EXCEDIO_VALOR_MAXIMO,
  EXCEDIO_PORCENTAJE,
  INGRESA_UN_PORCENTAJE,
  MONTO_MAXIMO,
  PORCENTAJE_MAXIMO,
  VALOR_SERVICIO_EXCEDIDO,

  // Empresas
  CONSULTAR_EMPRESAS,
  CONSULTAR_EMPRESA_POR_ID,
  CREAR_O_EDITAR_EMPRESA,
  ID_HOMOLOGACION_INVALIDO,

  // Reportes
  REPORTE_COMISION_REMESAS,
  REPORTE_BALANCE_GENERAL,
  REPORTE_DEPOSITOS_BANCARIOS,
  REPORTE_DEPOSITOS_BANCARIOS_ANULADOS,

  // COM Sidebar
  MODULO_INICIO,
  MODULO_EMPRESA,
  MODULO_REPORTES,
  ITEM_TARIFASERVICIO,
  ITEM_CREAREMPRESA,
  ITEM_DEPOSITOS_BANCARIOS,
  ITEM_COMISIONREMESAS,
  ITEM_BALANCE_GENERAL,
  ITEM_REPORTE_DEPOSITOS_BANCARIOS,
  ITEM_REPORTE_DEPOSITOS_BANCARIOS_ANULADOS,
  RUTA_TARIFASERVICIO,
  RUTA_CREAREMPRESA,
  RUTA_DEPOSITOS_BANCARIOS,
  RUTA_COMISIONREMESAS,
  RUTA_REPORTE_DEPOSITOS_BANCARIOS,
  RUTA_REPORTE_DEPOSITOS_BANCARIOS_ANULADOS
};
