import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { constantes } from 'src/app/core/data/Constantes';
import { UtilService } from 'src/app/core/services/util.service';

@Component({
  selector: 'app-filtro-fecha',
  templateUrl: './filtro-fecha.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: constantes.FORMATO_FECHAS }
  ],
})
export class FiltroFechaComponent {

  @Output() fechaSeleccionada = new EventEmitter();
  public fechaMax: Date = new Date();
  public form: FormGroup;

  constructor(private fb: FormBuilder, public utilService: UtilService) {}

  filtrarFecha(event: Event): void {
    const date = this.utilService.formatDateParamPipe((event.target as HTMLInputElement).value);
    this.fechaSeleccionada.emit({fecha: date});
  }

}
