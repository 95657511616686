import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output, forwardRef } from '@angular/core';
import { UtilService } from '../../../core/services/util.service';
import { SweetAlertService } from '../../../core/services/sweet-alert.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { constantes } from 'src/app/core/data/Constantes';
@Component({
  selector: 'app-campo-fecha',
  templateUrl: './campo-fecha.component.html',
  styleUrls: ['./campo-fecha.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: constantes.FORMATO_FECHAS }
  ],
})
export class CampoFechaComponent implements OnInit {

  public currectValue!: any;
  public sweetAlertService: SweetAlertService;
  public utilService: UtilService;
  public cdr: ChangeDetectorRef;

  public fechaInicialValor!: string;
  fechaSeleccionadaValor!: string | null;  // Guardar la fecha seleccionada
  fechaMax: Date = new Date();            // Fecha máxima (hoy)
  fechaMin: Date | null = null;           // Fecha mínima dinámica

  @Output() fechaSeleccionada = new EventEmitter<{ fecha: string; esMayor: boolean }>(); // Emitir fecha y estado de validación

  constructor(private readonly injector: Injector) {
    this.sweetAlertService = injector.get<SweetAlertService>(SweetAlertService);
    this.utilService = injector.get<UtilService>(UtilService);
    this.cdr = injector.get<ChangeDetectorRef>(ChangeDetectorRef);
  }

  ngOnInit(): void {
    this.fechaInicialValor = this.utilService.formatDatePipe();
  }

  public fechaInicialSeleccionada(event: Event): void {
    const fechaSeleccionada = this.utilService.formatDateParamPipe((event.target as HTMLInputElement).value);
    const esFechaMayorALaActual = this.utilService.validarFechaSeleccionada(fechaSeleccionada);
    const mensaje = `La fecha seleccionada ${fechaSeleccionada} es mayor a la actual.`;

    // Emitir la fecha seleccionada y su estado al componente padre
    this.fechaSeleccionada.emit({ fecha: fechaSeleccionada, esMayor: esFechaMayorALaActual });

    if (esFechaMayorALaActual) {
      this.sweetAlertService.sweetAlertInformativo(mensaje);
    } else {
      this.fechaSeleccionadaValor = fechaSeleccionada;  // Almacenar la fecha seleccionada
      this.cdr.detectChanges();  // Forzar la detección de cambios
    }
  }

  fechaMaxFinal() {
    if(!this.fechaMax) return this.fechaMax = this.utilService.formatDatePipe();
    else {
      const fechaSeleccionada = new Date(this.fechaSeleccionadaValor);
      return this.fechaMax = new Date(fechaSeleccionada.setMonth(fechaSeleccionada.getMonth() + 1));
    }
  }

  fechaMinInicial(): Date {
    if (this.fechaSeleccionadaValor) {
      return new Date(this.fechaSeleccionadaValor + 'T00:00:00');
    }
  }
}
