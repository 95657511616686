<label class="label_input" for="Fecha_Inicial">Fecha inicial</label>
<mat-form-field class="filtro__formCalendar" appearance="none">
    <input matInput [matDatepicker]="picker"
        [value]="utilService.formatDatePipe()"
        [max]="this.fechaMax"
        [min]="fechaMinInicial()"
        placeholder=" "
        (dateChange)="fechaInicialSeleccionada($event)"
        readonly="true">
    <mat-datepicker-toggle matSuffix [for]="picker" matTooltip="Fecha" matTooltipPosition="above"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>