
<div class="sidebar animate__animated" #animationMenu [hidden]="ocultoLogin" id="animationMenu">
    <div class="sidebar_logo">
        <img src="assets/imagenes/icons/icons_sidebar/logoGrande.svg" alt="Logo todomas" class="logoGrande" *ngIf="logoSidebar">
        <img src="assets/imagenes/icons/icons_sidebar/logoPequeno.svg" alt="Logo todomas" class="logoPequeno" *ngIf="!logoSidebar">
    </div>

    <div class="sidebar_modulo">
        <!-- COM Inicio -->
        <div class="modulo_opcion" (click)="redireccionamiento('inicio')">
            <div class="modulo_item" [ngClass]="moduloInicio ? 'moduloActivo' : ''">
                <img class="item_img" src="assets/imagenes/icons/icons_sidebar/homeAzul.svg" alt="" *ngIf="!homeSeleccionado">
            </div>
            <div class="modulo_texto">
                <p>Inicio</p>
            </div>
        </div>

        <div class="modulo_opcion" (click)="abrirSubitem('empresa')" *ngIf="validarOpcion(constantesOpciones.TARIFA_SERVICIO)">
            <div class="modulo_item" [ngClass]="tarifaServicio || crearEmpresa || depositosBancarios ? 'moduloActivo' : ''" #empresa>
                <img class="item_img" src="assets/imagenes/icons/icons_sidebar/empresaAzul.svg" alt="Icono empresa">
            </div>
            <div class="modulo_texto">
                <p>1. Empresa</p>
            </div>
            <div class="modulo_flecha">
                <img src="assets/imagenes/icons/icons_sidebar/flechaAzul.svg" alt="Flecha">
            </div>
        </div>

        <div *ngIf="empresaSeleccionado" class="animate__animated animate__fadeInLeft" #itemEmpresa>
            <div class="contenedor_subitems">
                <div class="subitem" (click)="redireccionamiento('tarifaServicio')">
                    <p [ngClass]="tarifaServicio ? 'itemActivo' : ''">- Tarifa servicio</p>
                </div>
            </div>
            <div class="contenedor_subitems">
                <div class="subitem" (click)="redireccionamiento('crearEmpresa')">
                    <p [ngClass]="crearEmpresa ? 'itemActivo' : ''">- Empresas</p>
                </div>
            </div>
            <div class="contenedor_subitems">
                <div class="subitem" (click)="redireccionamiento('depositosBancarios')">
                    <p [ngClass]="crearEmpresa ? 'itemActivo' : ''">- Depósitos bancarios</p>
                </div>
            </div>
        </div>

        <div class="modulo_opcion" (click)="abrirSubitem('reportes')" *ngIf="validarOpcion(constantesOpciones.COMISIONES_POR_REMESAS)">
            <div class="modulo_item" [ngClass]="(comisionRemesas || balanceGeneral || reporteDepositosBancarios || reporteDepositosBancariosAnulados) ? 'moduloActivo' : ''" #reportes>
                <img class="item_img" src="assets/imagenes/icons/icons_sidebar/reportesAzul.svg" alt="Icono reportes">
            </div>
            <div class="modulo_texto">
                <p>2. Reportes</p>
            </div>
            <div class="modulo_flecha">
                <img src="assets/imagenes/icons/icons_sidebar/flechaAzul.svg" alt="Flecha">
            </div>
        </div>

        <div *ngIf="reportesSeleccionado" class="animate__animated animate__fadeInLeft" #itemReportes>
            <div class="contenedor_subitems">
                <div class="subitem" (click)="redireccionamiento('comisionRemesas')">
                    <p [ngClass]="comisionRemesas  ? 'itemActivo' : ''">- Administración</p>
                </div>
            </div>
            <div class="contenedor_subitems">
                <div class="subitem" (click)="redireccionamiento('reporteDepositosBancarios')">
                    <p [ngClass]="reporteDepositosBancarios  ? 'itemActivo' : ''">- Depósitos bancarios</p>
                </div>
            </div>
            <div class="contenedor_subitems">
                <div class="subitem" (click)="redireccionamiento('reporteDepositosBancariosAnulados')">
                    <p [ngClass]="reporteDepositosBancariosAnulados  ? 'itemActivo' : ''">- Depósitos bancarios anulados</p>
                </div>
            </div>
        </div>
    </div>
</div>