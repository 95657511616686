export class EmpresaDto {
  id?: number;
  documento: string;
  nombre: string;
  telefono: string;
  ciudad: string;
  userName: string;
  contrasena: string;
  estado: string;
  token: string;
  comisionEnvioRemesa: string;
  comisionPagoRemesa: string;
  comisionPagoPremio: string;
  comisionRecargaBilletera: string;
  fechaHoraExpiracion: string;
  responsable: string;
  idHomologacion: number;
  activarInactivar: boolean;

  constructor(init?: Partial<EmpresaDto>) {
    if (init) {
      Object.assign(this, init);
    }
  }
}
