import { CurrencyPipe } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { constantes } from 'src/app/core/data/Constantes';
import { select } from 'src/app/core/data/utilDto';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit {

  @Input() nameSelect: string;
  @Input() formGroup!: FormGroup;
  @Input() lista: select[] = [];
  @Input() controlName!: string;
  @Input() filter = true;
  filterLista: any[] = [];

  constructor() { }

  ngOnInit() {
    // Inicializa la lista filtrada igual a la lista completa al inicio
    this.filterLista = [...this.lista];
  }

  trackByLista(index: number, tipoReporte: any) {
    return tipoReporte.id;
  }

}
