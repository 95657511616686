import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { constantes } from 'src/app/core/data/Constantes';
import { OpcionesPerfilRolDto } from 'src/app/core/data/OpcionesPerfilRolDto';
import { opciones } from 'src/app/core/data/rolOpciones';
import { SubjectsService } from 'src/app/core/services/subjects.service';
import { AutenticacionStoreService } from 'src/app/feature/autenticacion/store/autenticacion-store.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css'],
})
export class SideBarComponent implements OnInit, AfterViewInit {
  private subs = new SubSink();
  public estado: boolean;
  public homeSeleccionado: boolean;
  public empresaSeleccionado: boolean;
  public reportesSeleccionado: boolean;
  public logoSidebar: boolean;
  public ocultoLogin = true;
  public modulo: string;
  public viewporSize: number;

  // COM Marcar Item
  public selectMonitoreo: boolean;
  public selectEmpresa: boolean;
  public selectReportes: boolean;
  public selectrifas: boolean;

  // COM Modulos
  public moduloInicio: boolean;
  public selectinicio: boolean;
  public tarifaServicio: boolean;
  public crearEmpresa: boolean;
  public depositosBancarios: boolean;
  public comisionRemesas: boolean;
  public balanceGeneral: boolean;
  public reporteDepositosBancarios: boolean;
  public reporteDepositosBancariosAnulados: boolean;

  public pathRedireccion;
  public constantesOpciones = opciones;

  public opcionesUsuario$: Observable<any>;
  public opcionesUsuario: any;

  @ViewChild('animationMenu') animationMenu?: ElementRef;
  // COM Monitoreo
  @ViewChild('itemEmpresa') itemEmpresa?: ElementRef;

  @ViewChild('empresa') empresa?: ElementRef;
  @ViewChild('reportes') reportes?: ElementRef;
  @ViewChild('itemReportes') itemReportes?: ElementRef;
  ventaLoteriaOtrosProductos?: ElementRef;

  constructor(
    private subjectService: SubjectsService,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private router: Router,
    private autenticacionStoreService: AutenticacionStoreService,
    private store: Store
  ) {
    this.opcionesUsuario$ = this.autenticacionStoreService.obtenerOpcionesUsuario$();
    this.opcionesUsuario = new OpcionesPerfilRolDto();
  }

  ngAfterViewInit(): void {
    this.ocultarSidebarLogin();
    this.verificarEstadoSideBar();
    this.cdr.detectChanges();
    this.removerItemActivo();
    this.añadirItemActivo();
  }

  ngOnInit(): void {
    this.subjectService.hideSidebarNavbarGet.subscribe((resp): boolean => resp ? this.ocultoLogin = true : this.ocultoLogin = false);
    this.ocultarSidebarLogin();
    this.cdr.detectChanges();
  }

  public verificarEstadoSideBar(): void {
    this.subjectService.resolucionPantalla.subscribe(resp => {
      this.viewporSize = resp;
      if(this.viewporSize < 800){
        const removerHover = this.animationMenu.nativeElement;
        removerHover.style.transition = 'none';
      }
    });
    this.subs.add(
      this.subjectService.listadoRecursosAside.subscribe((estado: boolean) => {
        this.subjectService.iconSidebarGet.subscribe(resp => this.logoSidebar = resp ? true : false);
        this.estado = estado;
        if (this.estado) {
          this.cerrarModulos();
          this.renderer.addClass(
            this.animationMenu.nativeElement,
            'closeSidebar'
          );
          this.cerrarModulos();
        }
        if (!this.estado) {
          this.renderer.removeClass(
            this.animationMenu.nativeElement,
            'closeSidebar'
          );
          this.validarItemADescubrir();
        }
        if(this.viewporSize <= 768 && this.selectinicio && estado){
          this.renderer.addClass(this.animationMenu.nativeElement, 'closeSidebarTouch');
        }
        else {
          this.renderer.removeClass(this.animationMenu.nativeElement, 'closeSidebarTouch');
        }
      })
      );
  }

  abrirSubitem(subitem: string): void {
      this.subjectService.moveIconNavSet = true;
      this.logoSidebar = true;
      this.selectinicio = false;
      if(this.viewporSize <= 768){
        this.renderer.removeClass(this.animationMenu.nativeElement, 'closeSidebarTouch');
        this.renderer.removeClass(this.animationMenu.nativeElement, 'closeSidebar');
      }
      switch (subitem) {
        case constantes.MODULO_EMPRESA:
          this.abrirEmpresas();
          break;
        case constantes.MODULO_REPORTES:
          this.abrirReportes();
          break;
      }
  }

  public redireccionamiento(item: string) {
    switch (item) {
      case constantes.MODULO_INICIO:
        this.selectinicio = true;
        this.router.navigate([constantes.MODULO_INICIO]);
        break;
      case constantes.ITEM_TARIFASERVICIO:
        this.tarifaServicio = true;
        this.router.navigate([constantes.RUTA_TARIFASERVICIO]);
        break;
      case constantes.ITEM_CREAREMPRESA:
        this.crearEmpresa = true;
        this.router.navigate([constantes.RUTA_CREAREMPRESA]);
        break;
      case constantes.ITEM_DEPOSITOS_BANCARIOS:
        this.depositosBancarios = true;
        this.router.navigate([constantes.RUTA_DEPOSITOS_BANCARIOS]);
        break;
      case constantes.ITEM_COMISIONREMESAS:
        this.comisionRemesas = true;
        this.router.navigate([constantes.RUTA_COMISIONREMESAS]);
        break;
      case constantes.ITEM_REPORTE_DEPOSITOS_BANCARIOS:
        this.reporteDepositosBancarios = true;
        this.router.navigate([constantes.RUTA_REPORTE_DEPOSITOS_BANCARIOS]);
        break;
      case constantes.ITEM_REPORTE_DEPOSITOS_BANCARIOS_ANULADOS:
        this.reporteDepositosBancariosAnulados = true;
        this.router.navigate([constantes.RUTA_REPORTE_DEPOSITOS_BANCARIOS_ANULADOS]);
        break;
    }

    this.subjectService.listadoRecursosAsideData = this.viewporSize < 1100 ? true : false;
  }

  public añadirItemActivo(): void {
    this.subjectService.pathVistaToSideBarGet.subscribe({
      next: (path: string) => {
        this.pathRedireccion = path;
        this.cerrarModulos();
        this.removerItemActivo();
        this.removerModuloItem();
        this.validarItemADescubrir().then(resp => {
          switch (this.pathRedireccion) {
            case constantes.MODULO_INICIO:
              this.moduloInicio = true;
              break;
            case constantes.ITEM_TARIFASERVICIO:
              this.tarifaServicio = true;
              this.selectEmpresa = true;
              break;
            case constantes.ITEM_CREAREMPRESA:
              this.selectEmpresa = true;
              this.crearEmpresa = true;
              break;
            case constantes.ITEM_COMISIONREMESAS:
              this.comisionRemesas = true;
              this.selectEmpresa = true;
              break;
            case constantes.ITEM_BALANCE_GENERAL:
              this.balanceGeneral = true;
              break;
            case constantes.ITEM_DEPOSITOS_BANCARIOS:
              this.selectEmpresa = true;
              this.depositosBancarios = true;
              break;
            case constantes.ITEM_REPORTE_DEPOSITOS_BANCARIOS:
              this.reporteDepositosBancarios = true;
              this.reportesSeleccionado = true;
              break;
            case constantes.ITEM_REPORTE_DEPOSITOS_BANCARIOS_ANULADOS:
              this.reporteDepositosBancariosAnulados = true;
              this.reportesSeleccionado = true;
              break;
          }
          this.verificarEstadoSideBar();
        });
      },
    });
  }

  validarItemADescubrir(): Promise<boolean> {
    return new Promise(res => {
      this.subjectService.iconSidebarGet.subscribe(resp => this.logoSidebar = resp ? true : false);
      const empresaItems = [
        constantes.ITEM_TARIFASERVICIO,
        constantes.ITEM_CREAREMPRESA,
        constantes.ITEM_DEPOSITOS_BANCARIOS,
      ];

      const reportesItems = [
        constantes.ITEM_COMISIONREMESAS,
        constantes.ITEM_BALANCE_GENERAL,
        constantes.ITEM_REPORTE_DEPOSITOS_BANCARIOS,
        constantes.ITEM_REPORTE_DEPOSITOS_BANCARIOS_ANULADOS,
      ];

      this.empresaSeleccionado = empresaItems.includes(this.pathRedireccion);
      this.reportesSeleccionado = reportesItems.includes(this.pathRedireccion);

      if(!this.estado && this.viewporSize < 1100){
        this.cerrarModulos();
      }
      res(true);
    });
  }

  abrirEmpresas(): Promise<boolean> {
    return new Promise(res => {
      if (this.empresaSeleccionado) {
        this.renderer.addClass(this.itemEmpresa.nativeElement, 'animate__fadeOutLeft');
        setTimeout(() => {
          this.empresaSeleccionado = false;
        }, 800);
      } else {
        this.cerrarModulos();
        this.empresaSeleccionado = true;
      }
      res(true);
    });
  }
  abrirReportes(): Promise<boolean> {
    return new Promise(res => {
      if (this.reportesSeleccionado) {
        this.renderer.addClass(this.itemReportes.nativeElement, 'animate__fadeOutLeft');
        setTimeout(() => {
          this.reportesSeleccionado = false;
        }, 800);
      } else {
        this.cerrarModulos();
        this.reportesSeleccionado = true;
      }
      res(true);
    });
  }

  ocultarSidebarLogin(): void {
    this.subjectService.ocultarSidebarLoginGet.subscribe(respuesta => respuesta ? this.ocultoLogin = true : this.ocultoLogin = false);
  }

  cerrarModulos(): Promise<boolean> {
    return new Promise(res => {
      this.empresaSeleccionado = false;
      this.reportesSeleccionado = false;
    });
  }

  removerItemActivo() {
    this.moduloInicio = false;
    this.tarifaServicio = false;
    this.crearEmpresa = false;
    this.comisionRemesas = false;
    this.depositosBancarios = false;
    this.reporteDepositosBancarios = false;
    this.reporteDepositosBancariosAnulados = false;
  }

  removerModuloItem() {
    this.selectEmpresa = false;
    this.selectReportes = false;
  }

  //COM Opciones usuario
  public validarOpcion(valor: number) {
    this.opcionesUsuario$.subscribe((data: any) => {
      this.opcionesUsuario = data;
    });
    return Object.values(this.opcionesUsuario).includes(valor);
  }
}
