import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmpresaDto } from '../data/EmpresaDto';
import { Observable } from 'rxjs';
import { GenericDto } from 'src/app/core/data/GenericDto';
import { environment } from 'src/environments/environment';
import { constantes } from 'src/app/core/data/Constantes';
import { IBuscarEmpresa } from '../data/IEmpresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }

  public headers = {headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=UTF-8' })};

  public crearEmpresa(newEmpresa: EmpresaDto): Observable<GenericDto> {
    return this.http.post<GenericDto>(environment.api + constantes.CREAR_O_EDITAR_EMPRESA, newEmpresa, this.headers );
  }

  public obtenerEmpresas(filtro: any): Observable<any> {
    const params = new HttpParams().set('filtro', filtro);
    return this.http.get<GenericDto>(environment.api + constantes.CONSULTAR_EMPRESAS, {params});
  }

  public obtenerEmpresaPorId(id: number): Observable<GenericDto> {
    return this.http.get<GenericDto>(environment.api + constantes.CONSULTAR_EMPRESA_POR_ID + `?idempresa=${id}`);
  }

  public consultarTarifas(): Observable<GenericDto> {
    return this.http.get<GenericDto>(environment.api + '/adm/tarifatipoconcepto/consultarplanservicio');
  }

  public editarTarifas(object): Observable<GenericDto> {
    return this.http.post<GenericDto>(environment.api + '/adm/tarifatipoconcepto/modificarplanservicio', object, this.headers);
  }

  public almacenarDepositoBancario(object): Observable<GenericDto> {
    return this.http.post<GenericDto>(environment.api + '/adm/depositos/almacenardeposito', object, this.headers);
  }

  public obtenerDepositosBancario(object): Observable<GenericDto> {
    return this.http.get<GenericDto>(
      `${environment.api}/adm/depositos/consultardepositos?filtro=${object.filtro}&fecha=${object.fecha}&pagina=${object.pagina}&limite=${object.limite}`,
      this.headers);
  }
}
