import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SweetAlertService } from './sweet-alert.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public modalidadId: number;
  public cacheStorage: Cache;

  public encrypted: any = '';
  public decrypted: string;

  request: string;
  responce: string;

  constructor(private readonly sweetAlertService: SweetAlertService) {
    this.modalidadId = 0;
  }

  public convertirModalidad(modalidad?: string): number {

    switch (modalidad) {
      case 'QUINIELA':
        this.modalidadId = 1;
        break;
      case 'PALE':
        this.modalidadId = 2;
        break;
      case 'TRIPLETA':
        this.modalidadId = 3;
        break;
      case 'SUPERPALE':
        this.modalidadId = 4;
        break;
      case '4CIFRAS':
        this.modalidadId = 5;
        break;
      case '4CIFRASCOMB':
        this.modalidadId = 6;
        break;
    }
    return this.modalidadId;
  }

  public numeroTelefonicoAsteriscos(numero?: string): string {
    const numeroUno = numero.substring(0, 6);
    const numeroDos = numero.substring(6, 10);
    return numeroUno.replace(numeroUno, '******') + numeroDos;
  }


  public dividirApellido(apellido?: string): string {
    const apellidoSplit = apellido.split(' ');
    return apellidoSplit[0];
  }

  // COM Fechas ****************************************

  public formatDatePipe(): any {
    const fechaActual = new Date();
    const datePipe = new DatePipe('en-DO');
    return datePipe.transform(fechaActual, 'yyyy-MM-dd');
  }

  public fechaMinimaMes(fecha): any {
    const [año, mes, dia] = fecha.split('-');
    const newMes = (parseInt(mes, 10) - 1).toString().padStart(2, '0'); // Ajusta el mes y agrega un 0 si es necesario
    return `${año}-${newMes}-${dia}`;
  }

  public fechaMaximaMes(fecha): any {
    const [año, mes, dia] = fecha.split('-');
    const newMes = (parseInt(mes, 10) + 1).toString().padStart(2, '0');
    return `${año}-${newMes}-${dia}`;
  }

  public formatDateParamPipe(param?: any): string {
    const datePipe = new DatePipe('en-DO');
    return datePipe.transform(param, 'yyyy-MM-dd');
  }

  // Validar fecha maximo un mes de la fecha inicial
  // public validarFechaUnMes(param?: any): string {
  //   const [año, mes, dia] = param.split('-');
  //   const fechaMinima = new Date(año, mes - 1, dia);
  //   const fechaMaxima = fechaMinima.setMonth(fechaMinima.getMonth() + 1);
  //   const datePipe = new DatePipe('en-DO');
  //   return datePipe.transform(fechaMaxima, 'yyyy-MM-dd');
  // }

  public validarFechaMaxima(param?: any): string {
    const [año, mes, dia] = param.split('-');
    const fechaFinal = new Date(año, mes, dia);
    const fechaI = this.formatDateParamPipe(fechaFinal);

    const fechaActual = new Date();
    const datePipe = new DatePipe('en-DO');
    const fechaMaximaActual = datePipe.transform(fechaActual, 'yyyy-MM-dd');

    if(fechaI < fechaMaximaActual) {
      return fechaI;
    }
    if (fechaI > fechaMaximaActual){
      return fechaMaximaActual;
    }
  }

  public validarFechaSeleccionada(fecha: string): boolean {
    const fechaSeleccionada = this.formatDateParamPipe((fecha));
    const fechaActual = this.formatDateParamPipe(new Date());
    const mensaje = `La fecha seleccionada ${fechaSeleccionada} es mayor a actual.`;

    if (fechaSeleccionada > fechaActual) {
      this.sweetAlertService.sweetAlertInformativo(mensaje);
      return true;
    }
    else {
      return false;
    }
  }

  public fechaMinMes(): any {
    const fechaActual = new Date();
    const fechaMinima = fechaActual.setMonth(fechaActual.getMonth() - 1);
    const datePipe = new DatePipe('en-DO');
    return datePipe.transform(fechaMinima, 'yyyy-MM-dd');
  }

  public validarFechaMenorALaActual(fecha: string): boolean {
    const fechaSeleccionada = this.formatDateParamPipe((fecha));
    const fechaActual = this.formatDateParamPipe(new Date());
    const mensaje = `La fecha seleccionada ${fechaSeleccionada} es menor a actual.`;

    if (fechaSeleccionada < fechaActual) {
      this.sweetAlertService.sweetAlertInformativo(mensaje);
      return true;
    }
    else {
      return false;
    }
  }

  public validarFechaFinalMayorInicial(fechaInicial: string, fechaFinal: string): boolean {
    const fechaSeleccionadaInicial = this.formatDateParamPipe((fechaInicial));
    const fechaSeleccionadaFinal = this.formatDateParamPipe((fechaFinal));
    const mensaje = `La fecha final ${fechaSeleccionadaFinal} es menor a la fecha inicial ${fechaSeleccionadaInicial}.`;
    if (fechaSeleccionadaFinal < fechaSeleccionadaInicial) {
      this.sweetAlertService.sweetAlertInformativo(mensaje);
      return true;
    }
    else {
      return false;
    }
  }

  public validarFechaActual(fecha: string): boolean {
    const fechaSeleccionada = this.formatDateParamPipe((fecha));
    const fechaActual = this.formatDateParamPipe(new Date());
    if (fechaSeleccionada === fechaActual) {
      return true;
    }
    else {
      return false;
    }
  }



  // COM Horas ******************************************

  public obtenerHoraActual(): string {
    const myDate = new Date();
    const hours = myDate.getHours();
    const minutes = myDate.getMinutes();

    const min = minutes < 10 ? '0' + minutes : minutes;
    let horaActual: string;
    return horaActual = hours + ':' + min;

  }


  public compararHoraConHoraActual(horaIngresada: string, formato: string, fechaSeleccionada: string): boolean {

    // Divide la hora ingresada en horas y minutos
    // eslint-disable-next-line prefer-const
    let [hora, minutos] = horaIngresada.split(':');

    // Obtiene la hora actual
    const horaActual = new Date().getHours();
    const minutosActuales = new Date().getMinutes();
    const min = minutosActuales < 10 ? '0' + minutosActuales : minutosActuales;
    const formatoHora = formato;

    if (['PM'].includes(formatoHora)) {
      const horaModificada = Number(hora) + 12;
      hora = String(horaModificada);
    }

    // Convierte los valores a números enteros
    const horaIngresadaNum = Number(hora);
    const minutosIngresadosNum = Number(minutos);

    // Compara la hora ingresada con la hora actual
    // eslint-disable-next-line max-len
    if ((horaIngresadaNum < horaActual || (horaIngresadaNum === horaActual && minutosIngresadosNum < Number(min))) && this.validarFechaActual(fechaSeleccionada)) {
      this.sweetAlertService.sweetAlertInformativo('La hora ingresada es anterior a la hora actual.');
      return true;
    }
    else if ((horaIngresadaNum === horaActual && minutosIngresadosNum === Number(min)) && this.validarFechaActual(fechaSeleccionada)) {
      this.sweetAlertService.sweetAlertInformativo(
        `La hora no puede ser la actual en la fecha ${fechaSeleccionada}, elige una fecha u hora mayor.`);
      return true;
    } else {
      return false;
    }
  }

  formatoMoneda(numero) {
    const format = numero;

    const formattedValor = parseFloat(format).toFixed(2);
    const valorFinal = new Intl.NumberFormat('es-DO', {
      currency: 'DOP',
    }).format(parseFloat(formattedValor));
    return valorFinal;
  }
}
