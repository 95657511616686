import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-busqueda',
  templateUrl: './input-busqueda.component.html',
  styleUrls: ['./input-busqueda.component.css']
})
export class InputBusquedaComponent {

  @Output() busqueda = new EventEmitter();
  @Input() loading = false;

  buscarInformacion(event) {
    this.loading = true;
    this.busqueda.emit({filtro: event});
    setTimeout(() => this.loading = false, 300).unref();
  }
}
